import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const content = css`
  min-height: ${contentHeight}vh;
`;

const About = ({ data: { prismicAbout } }) => {
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1>{prismicAbout.data.title.text}</h1>
        <Row>
          <Col md="6">
            <Img
              className="rounded"
              fluid={prismicAbout.data.photo.localFile.childImageSharp.fluid}
            />
          </Col>
          <Col md="6">
            <div
              dangerouslySetInnerHTML={{
                __html: prismicAbout.data.description.html,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query AboutQuery {
    prismicAbout {
      data {
        title {
          text
        }
        photo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        description {
          html
        }
      }
    }
  }
`;
